import { CONTROLLED_DATE_TIME_PICKER_TYPES } from "@elliemae/ds-form-date-time-picker";
import { randomUUID, USStateTypes } from "@frontend/utils";
const EmptySchema = {
  schema: {
    theme: null,
    modules: [],
    screens: [
      {
        type: "screen_group",
        screens: []
      }
    ]
  },
  schemaRules: []
};
const NSSampleSchema = {
  schema: {
    theme: null,
    modules: [],
    screens: [
      {
        type: "screen_group",
        screens: [
          {
            // Refers to screens below
            id: "c8fc0171-c0c4-46fd-b81e-0c2e315512e2"
          },
          {
            id: "3cd6556e-a5e0-4c02-95ae-8da91a866ea0"
          },
          {
            id: "56cac222-e97d-4993-81c7-a0d86a92244a"
          },
          {
            id: "66cb28eb-7754-499e-bc0c-c399728f9598"
          }
        ]
      },
      {
        id: "c8fc0171-c0c4-46fd-b81e-0c2e315512e2",
        type: "screen",
        name: "General Loan Information",
        fields: [
          {
            type: "field_group",
            name: "VISIBLE",
            fields: [
              {
                label: "Effective Date",
                component_id: "Effective Date",
                id: "4e60c809-e23f-4d63-97fd-934f1036312e",
                required: true,
                type: "string",
                ui: {
                  col_size: 3,
                  widget: "datepicker",
                  type: CONTROLLED_DATE_TIME_PICKER_TYPES.DATE_TIME.FULL
                },
                visible: true,
                modelPath: "relockRequest.effectiveDate"
              },
              {
                label: "Loan Officer",
                component_id: "Loan Officer",
                ui: { col_size: 3, widget: "dropdown" },
                selectMultiple: false,
                visible: false,
                visible_check: {
                  handler: "62af1e06-20c3-4904-ba36-166c5ba2daf1"
                },
                id: "f25c8e38-d069-4bbe-8180-485c747f5c25",
                type: "enum",
                modelPath: "eppsUserName",
                options: [
                  {
                    id: "Sarah Plambeck",
                    text: "Sarah Plambeck",
                    value: "splambeck",
                    visible: true
                  }
                ]
              },
              {
                label: "Lien Position",
                component_id: "Lien Position",
                ui: { col_size: 3, widget: "dropdown" },
                selectMultiple: false,
                visible: true,
                required: true,
                id: "0f46e11d-acc2-4b20-b2d4-aba14ebe6f15",
                type: "enum",
                modelPath: "loanInformation.lienPosition",
                options: [
                  {
                    id: "1st Mortgage",
                    value: "1",
                    text: "1st Mortgage",
                    visible: true
                  },
                  {
                    id: "2nd Mortgage",
                    value: "2",
                    text: "2nd Mortgage",
                    visible: true
                  }
                ]
              },
              {
                label: "Loan Purpose",
                component_id: "Loan Purpose",
                ui: { col_size: 3, widget: "dropdown" },
                selectMultiple: false,
                required: true,
                visible: true,
                id: "7e7dd2a6-7ec9-4518-bfa2-71379c63f056",
                type: "enum",
                modelPath: "loanInformation.loanPurpose",
                options: [
                  {
                    id: "Purchase",
                    value: "1",
                    text: "Purchase",
                    visible: true
                  },
                  {
                    id: "Rate/Term Refinance",
                    value: "2",
                    text: "Rate/Term Refinance",
                    visible: true
                  },
                  {
                    id: "C/O Refinance",
                    value: "3",
                    text: "C/O Refinance",
                    visible: true
                  },
                  {
                    id: "Construction",
                    value: "4",
                    text: "Construction",
                    visible: true
                  },
                  {
                    id: "Construction-Perm",
                    value: "5",
                    text: "Construction-Perm",
                    visible: true
                  }
                ]
              },
              {
                label: "Purchase Price/Property Value",
                component_id: "Purchase Price/Property Value",
                id: "38c6c123-fef9-48a8-81bf-c8cc91ff625f",
                modelPath: "property.value",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true,
                visible_check: {
                  handler: "eddabb96-3e46-429f-93e8-3d33e02e4639"
                }
              },
              {
                label: "Property Value",
                component_id: "Property Value",
                id: "339654f1-08a0-4f3c-ae85-529d7d2b892d",
                modelPath: "property.value",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true,
                visible_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                }
              },
              {
                label: "1st Mortgage Balance",
                component_id: "1st Mortgage Balance",
                id: "084253d9-ac2f-4054-8e08-e2891183a9bd",
                modelPath: "loanInformation.secondMortgageAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true,
                visible_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                }
              },
              {
                label: "Loan Amount",
                component_id: "Loan Amount",
                id: "1132f407-1920-4b66-99de-312faafb89c9",
                modelPath: "loanInformation.firstMortgageAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true
              },
              {
                label: "Other Closed End 2nd Mortgage",
                component_id: "Other Closed End 2nd Mortgage",
                id: "42f30a45-aff8-49a8-87f0-b28e73e7dc68",
                modelPath: "loanInformation.totSubClosedEndMort",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                visible_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                }
              },
              {
                label: "Monthly Housing Payment",
                component_id: "Monthly Housing Payment",
                id: "dddbfff4-1bd1-426c-ae43-d25be094a8f1",
                modelPath: "loanInformation.otherPayment",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true,
                visible_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                }
              },
              {
                label: "Loan Amount/Initial Draw",
                component_id: "Loan Amount/Initial Draw",
                id: "ec856f68-1991-4240-be2d-6302cbebeeaf",
                modelPath: "loanInformation.secondMortgageAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                required: true,
                visible_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                }
              },
              {
                label: "Other HELOC Draws",
                component_id: "Other HELOC Draws",
                id: "6a75ff49-9935-47d4-9149-e1c068cb6ecf",
                modelPath: "loanInformation.otherHELOCDraws",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible_check: {
                  handler: "96052c26-2b63-4c86-a969-6f86c0166187"
                },
                visible: false,
                disabled: true
              },
              {
                label: "Max Loan Amount/Max Line",
                component_id: "Max Loan Amount/Max Line",
                id: "21aea877-8f9a-4be7-a3aa-1e7091e5db4d",
                modelPath: "loanInformation.loanAmountMaxLine",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                visible_check: {
                  handler: "322159db-fd06-4203-88cc-ba7dd14707bc"
                }
              },
              {
                label: "Govt Fee Factor",
                component_id: "Govt Fee Factor",
                id: "ddc0e03c-4405-47ad-a02a-532a560928fd",
                modelPath: "loanInformation.govtFeeFactor",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "percent"
                },
                visible: false,
                disabled: true,
                visible_check: {
                  handler: "dc1c8abd-0d8e-4d6f-ba01-36e484bccc05"
                }
              },
              {
                label: "Fee Amount",
                component_id: "Fee Amount",
                id: "cadc1f71-6855-4b04-a306-fab994c3489f",
                modelPath: "loanInformation.feeAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: false,
                disabled: false,
                visible_check: {
                  handler: "dc1c8abd-0d8e-4d6f-ba01-36e484bccc05"
                }
              },
              {
                label: "Fee Amount Financed",
                component_id: "Fee Amount Financed",
                id: "2f61ca2b-26e6-4548-a30d-67208f3d2128",
                modelPath: "loanInformation.feeAmountFinanced",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: false,
                disabled: false,
                visible_check: {
                  handler: "dc1c8abd-0d8e-4d6f-ba01-36e484bccc05"
                }
              },
              {
                label: "Fee Amount Paid In Cash",
                component_id: "Fee Amount Paid In Cash",
                id: "0216c5ef-156a-4dad-8012-1b63a384e592",
                modelPath: "loanInformation.feeAmountPaidinCash",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: false,
                disabled: false,
                visible_check: {
                  handler: "dc1c8abd-0d8e-4d6f-ba01-36e484bccc05"
                }
              },
              {
                label: "Other HELOC Lines",
                component_id: "Other HELOC Lines",
                id: "0a8ccab1-48bd-48f1-9c8d-f650a9735131",
                modelPath: "loanInformation.otherHELOCLines",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: false,
                disabled: true,
                visible_check: {
                  handler: "96052c26-2b63-4c86-a969-6f86c0166187"
                }
              },
              {
                label: "PMI, MIP Financed",
                component_id: "PMI, MIP Financed",
                id: "98aa0ee9-13f6-471d-998c-691f9d3600de",
                modelPath: "loanInformation.financedAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                visible_check: {
                  handler: "eddabb96-3e46-429f-93e8-3d33e02e4639"
                }
              },
              {
                label: "Total Loan Amount",
                component_id: "Total Loan Amount",
                id: "dff1b589-a9a3-475a-9b65-ba0d99119ae5",
                modelPath: "loanInformation.financedAmount",
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                disabled: true,
                visible_check: {
                  handler: "eddabb96-3e46-429f-93e8-3d33e02e4639"
                }
              },
              {
                type: "number",
                label: "LTV",
                component_id: "LTV",
                id: "9b474910-43a1-4f85-9fca-90aec89414a8",
                ui: {
                  col_size: 4,
                  widget: "percent"
                },
                visible: true,
                disable_check: {
                  handler: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
                },
                modelPath: "loanInformation.ltv"
              },
              {
                type: "number",
                label: "CLTV",
                component_id: "CLTV",
                id: "a2c47a8d-4cca-4764-9d14-bba23151b079",
                ui: {
                  col_size: 4,
                  widget: "percent"
                },
                visible: true,
                disabled: true,
                modelPath: "loanInformation.cltv"
              },
              {
                type: "number",
                label: "HCLTV",
                component_id: "HCLTV",
                id: "a5701c22-034e-4dfe-8c7a-3ab3778bc057",
                ui: {
                  col_size: 4,
                  widget: "percent"
                },
                visible: true,
                disabled: true,
                modelPath: "loanInformation.hcltv",
                visible_check: {
                  handler: "f377baba-059d-423f-8702-de8281d8ec57"
                }
              },
              {
                type: "number",
                label: "Cash Out",
                component_id: "Cash Out",
                id: "7c272b62-b841-46d0-9d98-a1a3825ca121",
                ui: {
                  col_size: 2,
                  widget: "currency"
                },
                visible: true,
                required: true,
                modelPath: "loanInformation.cashOut",
                visible_check: {
                  handler: "d72d3321-a47a-4b43-be78-54a8a7e0ced2"
                }
              },
              {
                type: "boolean",
                label: "Community/Affordable Second",
                component_id: "Community/Affordable Second",
                id: "9867fbec-90ad-4aa5-9e28-1163970a39c9",
                ui: { col_size: 4, widget: "checkbox" },
                visible: true,
                modelPath: "loanInformation.communityAffordableSecond"
              },
              {
                type: "boolean",
                label: "No Closing Costs",
                component_id: "No Closing Costs",
                id: "e759e74a-7f2c-4f69-80ab-37b75d0203a8",
                ui: { col_size: 4, widget: "checkbox" },
                visible: true,
                modelPath: "loanInformation.noClosingCost"
              },
              {
                type: "boolean",
                label: "Lender Fee Waiver",
                component_id: "Lender Fee Waiver",
                id: "40892cdc-f168-4010-a867-0da91454e7e7",
                visible: true,
                ui: { col_size: 4, widget: "checkbox" },
                modelPath: "loanInformation.lenderFeeWaiver"
              },
              {
                type: "boolean",
                label: "Closing Costs are included in the Loan Amount",
                component_id: "Closing Costs are included in the Loan Amount",
                id: "67768f38-184e-4551-b240-c0df5a396488",
                visible: true,
                visible_check: {
                  handler: "49cf07c3-2f06-41a1-8c5e-64580fa0730b"
                },
                ui: { col_size: 4, widget: "checkbox" },
                modelPath: "loanInformation.includeClosingCost"
              }
            ]
          },
          {
            type: "field_group",
            name: "DETAILED",
            fields: [
              {
                component_id: "2nd Mortgage Balance",
                id: "45cb7c7e-3091-41ea-84d6-c204de709325",
                label: "2nd Mortgage Balance",
                modelPath: "loanInformation.secondMortgageAmount",
                type: "number",
                visible: false,
                visible_check: {
                  handler: "eddabb96-3e46-429f-93e8-3d33e02e4639"
                },
                required_check: {
                  handler: "efe3a42a-b0a2-4330-801c-6a637537a19b"
                },
                ui: { col_size: 3, widget: "currency" }
              },
              {
                component_id: "2nd Mortgage Payment",
                id: "5d1f7712-6ef2-45b7-9d98-c21cd904b235",
                label: "2nd Mortgage Payment",
                modelPath: "loanInformation.otherPayment",
                type: "number",
                visible: false,
                visible_check: {
                  handler: "eddabb96-3e46-429f-93e8-3d33e02e4639"
                },
                required_check: {
                  handler: "efe3a42a-b0a2-4330-801c-6a637537a19b"
                },
                ui: { col_size: 3, widget: "currency" }
              },
              {
                label: "Commitment Type",
                component_id: "Commitment Type",
                ui: { col_size: 3, widget: "dropdown" },
                selectMultiple: false,
                required: true,
                visible: true,
                visible_check: {
                  handler: "b8e31c77-8a8f-4f43-b748-ab4d3404fa3d"
                },
                id: "46308681-4b5c-42b7-97c3-36c3c49f71c7",
                type: "enum",
                modelPath: "loanInformation.commitmentType",
                options: [
                  {
                    id: "Best Efforts",
                    value: "2",
                    text: "Best Efforts",
                    visible: true
                  },
                  {
                    id: "Mandatory",
                    value: "1",
                    text: "Mandatory",
                    visible: true
                  }
                ]
              },
              {
                label: "Delivery Type",
                component_id: "Delivery Type",
                ui: { col_size: 3, widget: "dropdown" },
                selectMultiple: false,
                required: true,
                visible: true,
                disabled: true,
                visible_check: {
                  handler: "b8e31c77-8a8f-4f43-b748-ab4d3404fa3d"
                },
                id: "6b9e4e2a-689d-416f-a78d-b16ae1f14798",
                type: "enum",
                modelPath: "loanInformation.deliveryType",
                options: [
                  {
                    id: "Individual Best Efforts",
                    value: "2",
                    text: "Individual Best Efforts",
                    visible: true
                  },
                  {
                    id: "Individual Mandatory",
                    value: "1",
                    text: "Individual Mandatory",
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: "56cac222-e97d-4993-81c7-a0d86a92244a",
        type: "screen",
        name: "Product Information",
        fields: [
          {
            type: "field_group",
            name: "VISIBLE",
            fields: [
              {
                label: "Loan Terms",
                component_id: "Loan Terms",
                helptext: "Select one or more loan terms",
                id: "cb3f524e-8051-4a14-a0a2-e488c07ced41",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                selectMultiple: true,
                modelPath: "productType",
                options: [
                  {
                    id: "new_fixed",
                    value: "132",
                    text: "NEW FIXED (31 years)",
                    visible: true
                  },
                  {
                    id: "test_ap_68100",
                    value: "169",
                    text: "test ap 68100 (30 years)",
                    visible: true
                  },
                  {
                    id: "30_year_fixed",
                    value: "1",
                    text: "30 Year Fixed",
                    visible: true
                  },
                  {
                    id: "25_year_fixed",
                    value: "20",
                    text: "25 Year Fixed",
                    visible: true
                  },
                  {
                    id: "20_year_fixed",
                    value: "2",
                    text: "20 Year Fixed",
                    visible: true
                  },
                  {
                    id: "15_year_fixed",
                    value: "3",
                    text: "15 Year Fixed",
                    visible: true
                  },
                  {
                    id: "term_17_fixed",
                    value: "150",
                    text: "Term 17 Fixed (17 years)",
                    visible: true
                  },
                  {
                    id: "12_year_fixed",
                    value: "23",
                    text: "12 Year Fixed",
                    visible: true
                  },
                  {
                    id: "10_year_fixed",
                    value: "4",
                    text: "10 Year Fixed",
                    visible: true
                  },
                  {
                    id: "40_year_fixed",
                    value: "14",
                    text: "40 Year Fixed",
                    visible: true
                  },
                  {
                    id: "1_year_fixed",
                    value: "24",
                    text: "1 Year Fixed",
                    visible: true
                  },
                  {
                    id: "2_year_fixed",
                    value: "25",
                    text: "2 Year Fixed",
                    visible: true
                  },
                  {
                    id: "3_year_fixed",
                    value: "26",
                    text: "3 Year Fixed",
                    visible: true
                  },
                  {
                    id: "4_year_fixed",
                    value: "27",
                    text: "4 Year Fixed",
                    visible: true
                  },
                  {
                    id: "5_year_fixed",
                    value: "19",
                    text: "5 Year Fixed",
                    visible: true
                  },
                  {
                    id: "30_year_6_months",
                    value: "99",
                    text: "30 Year 6 Months",
                    visible: true
                  },
                  {
                    id: "30_year_12_months",
                    value: "101",
                    text: "30 Year 12 Months",
                    visible: true
                  },
                  {
                    id: "30_year_9_months",
                    value: "100",
                    text: "30 Year 9 Months",
                    visible: true
                  },
                  {
                    id: "1_year_arm_180",
                    value: "72",
                    text: "1 Year ARM (15 years, 180 months)",
                    visible: true
                  },
                  {
                    id: "2_year_arm_180",
                    value: "98",
                    text: "2 Year ARM (15 years, 180 months)",
                    visible: true
                  },
                  {
                    id: "3_year_arm_300",
                    value: "63",
                    text: "3 Year ARM (25 years, 300 months)",
                    visible: true
                  },
                  {
                    id: "5_year_2mo_arm_240",
                    value: "120",
                    text: "5 Year 2mo ARM (20 years, 240 months)",
                    visible: true
                  },
                  {
                    id: "5_year_arm_480",
                    value: "68",
                    text: "5 Year ARM (40 years, 480 months)",
                    visible: true
                  },
                  {
                    id: "7_year_arm_300",
                    value: "61",
                    text: "7 Year ARM (25 years, 300 months)",
                    visible: true
                  },
                  {
                    id: "7_year_arm_480",
                    value: "69",
                    text: "7 Year ARM (40 years, 480 months)",
                    visible: true
                  },
                  {
                    id: "10_year_arm_240",
                    value: "58",
                    text: "10 Year ARM (20 years, 240 months)",
                    visible: true
                  },
                  {
                    id: "balloon_15_25",
                    value: "118",
                    text: "15/25 Balloon (15 years, 180 months amortized over 25 years)",
                    visible: true
                  },
                  {
                    id: "balloon_30_15",
                    value: "13",
                    text: "30/15 Balloon (15 years, 180 months amortized over 30 years)",
                    visible: true
                  },
                  {
                    id: "heloc_360",
                    value: "65",
                    text: "HELOC (30 years, 360 months)",
                    visible: true
                  },
                  {
                    id: "heloc_300",
                    value: "77",
                    text: "HELOC (25 years, 300 months)",
                    visible: true
                  },
                  {
                    id: "heloc_240",
                    value: "64",
                    text: "HELOC (20 years, 240 months)",
                    visible: true
                  },
                  {
                    id: "heloc_180",
                    value: "67",
                    text: "HELOC (15 years, 180 months)",
                    visible: true
                  },
                  {
                    id: "heloc_120",
                    value: "21",
                    text: "HELOC (10 years, 120 months)",
                    visible: true
                  },
                  {
                    id: "7_year_arm_180",
                    value: "48",
                    text: "7 Year ARM (15 years, 180 months)",
                    visible: true
                  },
                  {
                    id: "7_year_arm_240",
                    value: "56",
                    text: "7 Year ARM (20 years, 240 months)",
                    visible: true
                  },
                  {
                    id: "7_year_arm_348",
                    value: "85",
                    text: "7 Year ARM (29 years, 348 months)",
                    visible: true
                  },
                  {
                    id: "10_year_arm_180",
                    value: "47",
                    text: "10 Year ARM (15 years, 180 months)",
                    visible: true
                  },
                  {
                    id: "10_year_arm_348",
                    value: "83",
                    text: "10 Year ARM (29 years, 348 months)",
                    visible: true
                  },
                  {
                    id: "10_year_arm_480",
                    value: "55",
                    text: "10 Year ARM (40 years, 480 months)",
                    visible: true
                  },
                  {
                    id: "30_15_balloon",
                    value: "13",
                    text: "30/15 Balloon (30 years amortized over 15 years)",
                    visible: true
                  },
                  {
                    id: "7_20_balloon",
                    value: "115",
                    text: "7/20 Balloon (20 years amortized over 7 years)",
                    visible: true
                  },
                  {
                    id: "7_15_balloon",
                    value: "116",
                    text: "7/15 Balloon (15 years amortized over 7 years)",
                    visible: true
                  },
                  {
                    id: "heloc_294",
                    value: "117",
                    text: "HELOC (25 years, 294 months)",
                    visible: true
                  },
                  {
                    id: "heloc_300_6_month",
                    value: "90",
                    text: "HELOC (25 years, 300 months with 6-month period)",
                    visible: true
                  },
                  {
                    id: "30_year_6_months",
                    value: "99",
                    text: "30 Year 6 Months (special term)",
                    visible: true
                  },
                  {
                    id: "30_year_12_months",
                    value: "101",
                    text: "30 Year 12 Months (special term)",
                    visible: true
                  },
                  {
                    id: "30_year_9_months",
                    value: "100",
                    text: "30 Year 9 Months (special term)",
                    visible: true
                  },
                  {
                    id: "5_year_2mo_arm",
                    value: "119",
                    text: "5 Year 2mo ARM (special term)",
                    visible: true
                  },
                  {
                    id: "1_year_arm_288",
                    value: "113",
                    text: "1 Year ARM (24 years, 288 months)",
                    visible: true
                  },
                  {
                    id: "5_year_2mo_arm_180",
                    value: "122",
                    text: "5 Year 2mo ARM (15 years, 180 months)",
                    visible: true
                  }
                ]
              },
              {
                label: "Custom Term",
                component_id: "Custom Term",
                id: "8361574e-6f1c-4c4b-9b20-debd419c38df",
                required: false,
                type: "string",
                ui: { col_size: 4, widget: "text" },
                visible: true,
                modelPath: "loanInformation.term"
              },
              {
                label: "Standard Products",
                component_id: "Standard Products",
                helptext: "Select one or more standard products",
                id: "fa98ef18-bf10-40b2-8334-ca4627e718f5",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                selectMultiple: true,
                modelPath: "standardProducts",
                options: [
                  {
                    id: "agency",
                    value: "1",
                    text: "Agency",
                    visible: true
                  },
                  {
                    id: "non-agency",
                    value: "5",
                    text: "Non-agency",
                    visible: true
                  },
                  {
                    id: "fha",
                    value: "2",
                    text: "FHA",
                    visible: true
                  },
                  {
                    id: "va",
                    value: "3",
                    text: "VA",
                    visible: true
                  },
                  {
                    id: "usda",
                    value: "4",
                    text: "USDA",
                    visible: true
                  },
                  {
                    id: "non-qm",
                    value: "6",
                    text: "Non-QM",
                    visible: true
                  }
                ]
              },
              {
                label: "Target Value",
                component_id: "Target Value",
                id: "bccf727d-4b74-44e4-938c-47a7dc54ac2f",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "loanInformation.targetLabel",
                selectMultiple: false,
                options: [
                  { id: "Rate", value: "Rate", text: "Rate", visible: true },
                  { id: "Price", value: "Price", text: "Price", visible: true }
                ]
              },
              {
                label: "Target Price",
                component_id: "Target Price",
                id: "1e8f263a-31ea-45ba-aa8c-926971d77c38",
                required: true,
                type: "number",
                ui: { col_size: 4 },
                visible: true,
                modelPath: "loanInformation.target"
              },
              {
                label: "Target Rate",
                component_id: "Target Rate",
                id: "17bd0af2-bbd2-453b-a18b-7faaec9d463f",
                required: true,
                type: "number",
                ui: { col_size: 4, widget: "percent" },
                visible: true,
                modelPath: "loanInformation.target"
              },
              {
                label: "FHA Case Number Date",
                component_id: "FHA Case Number Date",
                id: "f2bb27e2-67b0-4812-a8a6-c0bf27e4df47",
                required: false,
                type: "string",
                ui: {
                  col_size: 3,
                  widget: "datepicker",
                  type: CONTROLLED_DATE_TIME_PICKER_TYPES.DATE.FULL
                },
                visible: true,
                modelPath: "loanInformation.fhaCaseNumberDate"
              },
              {
                label: "Special Products",
                component_id: "Special Products",
                id: "3a5f2d3f-91b9-4d34-9bdc-ee80081a66fa",
                required: false,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                selectMultiple: true,
                visible: true,
                modelPath: "specialProducts",
                options: [
                  {
                    id: "asset_utilization",
                    value: "752",
                    text: "Asset Utilization",
                    visible: true
                  },
                  {
                    id: "fha_100_repo",
                    value: "73",
                    text: "FHA $100 Repo",
                    visible: true
                  },
                  {
                    id: "fha_203h",
                    value: "192",
                    text: "FHA 203H",
                    visible: true
                  },
                  {
                    id: "fha_203k_rehab",
                    value: "62",
                    text: "FHA 203K Rehab",
                    visible: true
                  },
                  {
                    id: "fha_203k_streamline",
                    value: "115",
                    text: "FHA 203K Streamline",
                    visible: true
                  },
                  {
                    id: "fha_streamline",
                    value: "27",
                    text: "FHA Streamline",
                    visible: true
                  },
                  {
                    id: "fhlmc_refi_possible",
                    value: "1427",
                    text: "FHLMC Refi Possible",
                    visible: true
                  },
                  {
                    id: "fnma_refi_now",
                    value: "1426",
                    text: "FNMA Refi Now",
                    visible: true
                  },
                  {
                    id: "foreign_national",
                    value: "135",
                    text: "Foreign National",
                    visible: true
                  },
                  {
                    id: "home_possible",
                    value: "20",
                    text: "HomePossible",
                    visible: true
                  },
                  {
                    id: "home_ready",
                    value: "556",
                    text: "HomeReady",
                    visible: true
                  },
                  {
                    id: "homestyle",
                    value: "109",
                    text: "Homestyle",
                    visible: true
                  },
                  {
                    id: "hud_section_184",
                    value: "136",
                    text: "HUD Section 184",
                    visible: true
                  },
                  {
                    id: "professional",
                    value: "892",
                    text: "Professional",
                    visible: true
                  },
                  {
                    id: "renovation",
                    value: "1397",
                    text: "Renovation",
                    visible: true
                  },
                  {
                    id: "usda_assist",
                    value: "703",
                    text: "USDA Assist",
                    visible: true
                  },
                  {
                    id: "usda_renovation",
                    value: "1207",
                    text: "USDA Renovation",
                    visible: true
                  },
                  {
                    id: "usda_streamline",
                    value: "704",
                    text: "USDA Streamline",
                    visible: true
                  },
                  {
                    id: "va_irrrl",
                    value: "55",
                    text: "VA IRRRL",
                    visible: true
                  },
                  { id: "z", value: "1938", text: "z", visible: true }
                ]
              }
            ]
          },
          {
            type: "field_group",
            name: "DETAILED",
            fields: [
              {
                label: "Product Options",
                component_id: "Product Options",
                id: "7c327485-f6dd-4aa5-a9fe-a4b85790cf4f",
                required: false,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                selectMultiple: true,
                modelPath: "productOptions",
                options: [
                  {
                    id: "40-year_Amortization",
                    value: "5",
                    text: "40-year Amortization",
                    visible: true
                  },
                  {
                    id: "50-year_Amortization",
                    value: "6",
                    text: "50-year Amortization",
                    visible: true
                  },
                  {
                    id: "6-Month_Promo",
                    value: "12",
                    text: "6 Month Promo",
                    visible: true
                  },
                  {
                    id: "abc1",
                    value: "31",
                    text: "abc1",
                    visible: true
                  },
                  {
                    id: "Another_Option_epps-55061",
                    value: "64",
                    text: "Another Option epps-55061",
                    visible: true
                  },
                  {
                    id: "Caution_Non_Saleable",
                    value: "14",
                    text: "Caution/Non-Saleable",
                    visible: true
                  },
                  {
                    id: "CPMMohanOptions",
                    value: "27",
                    text: "CPMMohanOptions",
                    visible: true
                  },
                  {
                    id: "DPA",
                    value: "13",
                    text: "DPA",
                    visible: true
                  },
                  {
                    id: "Duty_To_Serve",
                    value: "102",
                    text: "Duty To Serve",
                    visible: true
                  },
                  {
                    id: "EPMI",
                    value: "8",
                    text: "EPMI",
                    visible: true
                  },
                  {
                    id: "EPPS-55061_2023-10-06",
                    value: "99",
                    text: "EPPS-55061_2023-10-06 07:38:40",
                    visible: true
                  },
                  {
                    id: "IMAGIN",
                    value: "9",
                    text: "IMAGIN",
                    visible: true
                  },
                  {
                    id: "Interest_Only",
                    value: "4",
                    text: "Interest Only",
                    visible: true
                  },
                  {
                    id: "InvOption_Test",
                    value: "10",
                    text: "InvOption_Test",
                    visible: true
                  },
                  {
                    id: "Lender_paid_MI",
                    value: "1",
                    text: "Lender-paid MI",
                    visible: true
                  },
                  {
                    id: "Mohan_Global_Option_2",
                    value: "24",
                    text: "Mohan Global Option 2",
                    visible: true
                  },
                  {
                    id: "No_AVM",
                    value: "15",
                    text: "No AVM",
                    visible: true
                  },
                  {
                    id: "No_MI",
                    value: "7",
                    text: "No MI",
                    visible: true
                  },
                  {
                    id: "Testing_on_Release",
                    value: "11",
                    text: "Testing on Release",
                    visible: true
                  },
                  {
                    id: "Waive_Escrows",
                    value: "2",
                    text: "Waive Escrows",
                    visible: true
                  }
                ]
              },
              {
                label: "Pre-payment Penalty",
                component_id: "Pre-payment Penalty",
                id: "9f417416-9645-423c-9dd7-22a9b9544df8",
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "loanInformation.prepaymentPenaltyTerm",
                selectMultiple: false,
                options: [
                  { id: "None", value: "0", text: "None", visible: true },
                  { id: "1yr", value: "1", text: "1 yr", visible: true },
                  {
                    id: "2yrs",
                    value: "2",
                    text: "2 yrs",
                    visible: true
                  },
                  {
                    id: "3yrs",
                    value: "3",
                    text: "3 yrs",
                    visible: true
                  },
                  {
                    id: "4yrs",
                    value: "4",
                    text: "4 yrs",
                    visible: true
                  },
                  {
                    id: "5yrs",
                    value: "5",
                    text: "5 yrs",
                    visible: true
                  }
                ]
              },
              {
                label: "Compensation Type",
                component_id: "Compensation Type",
                id: "198c9ec8-0f44-41ce-ab3e-8a4c7b01be20",
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "compensation.model",
                selectMultiple: false,
                options: [
                  {
                    id: "Lender Paid",
                    // model is 2
                    value: "2",
                    text: "Lender Paid",
                    visible: true
                  },
                  {
                    id: "Borrower Paid",
                    // model is 1
                    value: "1",
                    text: "Borrower Paid",
                    visible: true
                  }
                ]
              },
              {
                label: "Temporary Buydown Type",
                component_id: "Temporary Buydown Type",
                helptext: "This must be updated in your loan origination system and resubmitted to ICE PPE",
                id: "d9fcd334-b39c-4ead-a745-88f621f77ca4",
                required: false,
                disabled: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "buydownType",
                selectMultiple: false,
                options: [
                  { id: "None", value: "None", text: "None", visible: true },
                  {
                    id: "3-2-1 Buydown",
                    value: "3-2-1 Buydown",
                    text: "3-2-1",
                    visible: true
                  },
                  {
                    id: "2-1 Buydown",
                    value: "2-1 Buydown",
                    text: "2-1",
                    visible: true
                  },
                  {
                    id: "1-0 Buydown",
                    value: "1-0 Buydown",
                    text: "1-0",
                    visible: true
                  },
                  {
                    id: "1.5-0.75 Buydown",
                    value: "1.5-0.75 Buydown",
                    text: "1.5-0.75",
                    visible: true
                  },
                  {
                    id: "1-0.5 Buydown",
                    value: "1-0.5 Buydown",
                    text: "1-0.5",
                    visible: true
                  },
                  {
                    id: "1-0 Buydown",
                    value: "1-0 Buydown",
                    text: "1-0",
                    visible: true
                  },
                  {
                    id: "1-1 Buydown",
                    value: "1-1",
                    text: "1-1",
                    visible: true
                  }
                ]
              },
              {
                label: "Temporary Buydown Contributor",
                component_id: "Temporary Buydown Contributor",
                id: "0d293499-d65b-459d-9e7c-6ddda37b9053",
                required: false,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "buydownContributor",
                selectMultiple: false,
                options: [
                  {
                    value: "0",
                    text: "None",
                    id: "None",
                    visible: true
                  },
                  {
                    value: "1",
                    text: "Lender",
                    id: "Lender",
                    visible: true
                  },
                  {
                    value: "2",
                    text: "Seller",
                    id: "Seller",
                    visible: true
                  },
                  {
                    value: "3",
                    text: "Builder",
                    id: "Builder",
                    visible: true
                  },
                  {
                    value: "4",
                    text: "Borrower",
                    id: "Borrower",
                    visible: true
                  },
                  {
                    value: "5",
                    text: "Unassigned",
                    id: "Unassigned",
                    visible: true
                  },
                  {
                    value: "6",
                    text: "Parent",
                    id: "Parent",
                    visible: true
                  },
                  {
                    value: "7",
                    text: "Non-Parent Relative",
                    id: "Non-Parent Relative",
                    visible: true
                  },
                  {
                    value: "8",
                    text: "Unrelated Friend",
                    id: "Unrelated Friend",
                    visible: true
                  },
                  {
                    value: "9",
                    text: "Employer",
                    id: "Employer",
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: "3cd6556e-a5e0-4c02-95ae-8da91a866ea0",
        type: "screen",
        name: "Finance Information",
        fields: [
          {
            type: "field_group",
            name: "VISIBLE",
            fields: [
              {
                label: "Employment",
                component_id: "Employment",
                id: "107ee0fe-ef21-4ba4-a52a-59793f7fcef4",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "employment",
                options: [
                  {
                    id: "Employed",
                    value: "Employed or Retired (can Verify)",
                    text: "Employed or Retired (can Verify)",
                    visible: true
                  },
                  {
                    id: "SelfEmployed",
                    value: "Self Employed (can Verify)",
                    text: "Self Employed (can Verify)",
                    visible: true
                  },
                  {
                    id: "Unemployed",
                    value: "Will Not Verify or Unemployed",
                    text: "Will Not Verify or Unemployed",
                    visible: true
                  }
                ]
              },
              {
                label: "Assets",
                component_id: "Assets",
                id: "5ce9c244-8cb3-45fa-98a1-a7bae86dd6e0",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "asset",
                options: [
                  { id: "Cash", value: "Cash", text: "Cash", visible: true },
                  {
                    id: "FullAssets",
                    value: "Full Assets",
                    text: "Full Assets",
                    visible: true
                  },
                  {
                    id: "StatedAssets",
                    value: "Stated Assets",
                    text: "Stated Assets",
                    visible: true
                  },
                  {
                    id: "NoAssets",
                    value: "No Assets",
                    text: "No Assets",
                    visible: true
                  }
                ]
              },
              {
                label: "Income",
                component_id: "Income",
                id: "9552759a-2a01-4057-9e3f-58461349d1ff",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "income",
                options: [
                  {
                    id: "NoIncome",
                    value: "No Income",
                    text: "No Income",
                    visible: true
                  },
                  {
                    id: "FullIncome",
                    value: "Full Income",
                    text: "Full Income",
                    visible: true
                  },
                  {
                    id: "StatedIncome",
                    value: "Stated Income",
                    text: "Stated Income",
                    visible: true
                  }
                ]
              },
              {
                label: "Non-QM Doc Level",
                component_id: "Non-QM Doc Level",
                id: "9c7bab92-2ec9-420a-9de2-d7ab576013ba",
                required: true,
                type: "enum",
                ui: { col_size: 4, widget: "dropdown" },
                visible: true,
                modelPath: "borrowerFinancialHistory.nonQMDocLevel",
                options: [
                  {
                    id: "1",
                    value: "24 Months W-2/Tax Returns",
                    text: "24 Months W-2/Tax Returns",
                    visible: true
                  },
                  {
                    id: "2",
                    value: "12 Months W-2/Tax Returns",
                    text: "12 Months W-2/Tax Returns",
                    visible: true
                  },
                  {
                    id: "3",
                    value: "24 Months Personal Bank Statements",
                    text: "24 Months Personal Bank Statements",
                    visible: true
                  },
                  {
                    id: "4",
                    value: "12 Months Personal Bank Statements",
                    text: "12 Months Personal Bank Statements",
                    visible: true
                  },
                  {
                    id: "5",
                    value: "24 Months Business Bank Statements",
                    text: "24 Months Business Bank Statements",
                    visible: true
                  },
                  {
                    id: "6",
                    value: "12 Months Business Bank Statements",
                    text: "12 Months Business Bank Statements",
                    visible: true
                  },
                  {
                    id: "7",
                    value: "Asset Depletion",
                    text: "Asset Depletion",
                    visible: true
                  },
                  {
                    id: "8",
                    value: "24 Months 1099",
                    text: "24 Months 1099",
                    visible: true
                  },
                  {
                    id: "9",
                    value: "12 Months 1099",
                    text: "12 Months 1099",
                    visible: true
                  },
                  {
                    id: "10",
                    value: "24 Months P&L",
                    text: "24 Months P&L",
                    visible: true
                  },
                  {
                    id: "11",
                    value: "12 Months P&L",
                    text: "12 Months P&L",
                    visible: true
                  },
                  {
                    id: "12",
                    value: "WVOE",
                    text: "WVOE",
                    visible: true
                  }
                ]
              },
              {
                label: "Representative Credit Score",
                component_id: "Representative Credit Score",
                id: "9ab17071-54f9-4b48-9c71-bd3817bad26a",
                required: false,
                type: "number",
                ui: { col_size: 4, widget: "creditScore", show_label: true },
                show_label: true,
                visible: true,
                modelPath: "borrowers[0].borrowerFinanancial.creditScore"
              },
              {
                label: "Avg. Repr Credit Score",
                component_id: "Avg. Repr Credit Score",
                id: "5e29a0c8-bdfa-4aff-a353-666d9349f1b4",
                required: false,
                type: "number",
                ui: { col_size: 4, widget: "creditScore", show_label: true },
                visible: true,
                disabled: true,
                modelPath: "loanInformation.averageRepresentativeCreditScore"
              },
              {
                label: "No Credit Score",
                component_id: "No Credit Score",
                id: "d1c44ee2-6594-41bd-9e1e-90bdf2618081",
                type: "boolean",
                ui: { col_size: 4, widget: "checkbox" },
                visible: true,
                modelPath: "borrowerFinancialHistory.noCreditScores"
              },
              {
                label: "First Time Home Buyer",
                component_id: "First Time Home Buyer",
                id: "b99273b8-8aa9-485a-8601-24b486850568",
                type: "boolean",
                ui: { col_size: 4, widget: "checkbox" },
                visible: true,
                modelPath: "borrowerFinancialHistory.firstTimeHomeBuyers"
              },
              {
                label: "Borrower can demonstrate 12-month mortgage/rental history",
                component_id: "Borrower can demonstrate 12-month mortgage/rental history",
                id: "87207af7-cd4e-4f99-9f55-a484b7414d26",
                type: "boolean",
                ui: { col_size: 4, widget: "checkbox" },
                visible: true,
                modelPath: "borrowerFinancialHistory.demonstrateHousingPaymentHistory"
              }
            ]
          },
          {
            type: "field_group",
            name: "DETAILED",
            fields: [
              {
                label: "Borrower Assets, Debt, and Income",
                component_id: "Borrower Details",
                id: "418fd10e-a116-414f-b9d6-821ca3ca2bf7",
                type: "field_group",
                visible: true,
                ui: {},
                fields: [
                  {
                    label: "Borrower First Name",
                    component_id: "Borrower First Name",
                    id: "214ea428-f7b5-45b7-9883-f86f074a038a",
                    type: "string",
                    visible: true,
                    ui: { col_size: 5 },
                    modelPath: "borrowers[0].firstName"
                  },
                  {
                    label: "Borrower Last Name",
                    component_id: "Borrower Last Name",
                    id: "603e7966-b95f-4d16-812e-08c2dd81d14d",
                    type: "string",
                    visible: true,
                    ui: { col_size: 5 },
                    modelPath: "borrowers[0].lastName"
                  },
                  {
                    label: "Citizenship",
                    component_id: "Citizenship",
                    id: "c5e10413-a72c-4e69-9405-163f703abb35",
                    type: "enum",
                    visible: true,
                    ui: { col_size: 5, widget: "dropdown" },
                    modelPath: "borrowers[0].citizenship",
                    options: [
                      {
                        id: "202",
                        text: "U.S. Citizen",
                        value: "1",
                        visible: true
                      },
                      {
                        id: "203",
                        text: "Permanent Resident Alien",
                        value: "2",
                        visible: true
                      },
                      {
                        id: "204",
                        text: "Non-Permanent Resident Alien",
                        value: "3",
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "LIQUID ASSETS",
                    component_id: "LIQUID ASSETS",
                    id: "586aee12-9ed5-4127-993e-435465a0bfe2",
                    type: "number",
                    visible: true,
                    ui: { col_size: 5, widget: "currency" },
                    modelPath: "borrowers[0].borrowerFinanancial.liquidAsset"
                  },
                  {
                    label: "RETIREMENT ASSETS",
                    component_id: "RETIREMENT ASSETS",
                    id: "c7c24225-2bf3-4499-9684-8b25f6225087",
                    type: "number",
                    visible: true,
                    ui: { col_size: 5, widget: "currency" },
                    modelPath: "borrowers[0].borrowerFinanancial.retirementAsset"
                  },
                  {
                    label: "ANNUAL INCOME",
                    component_id: "ANNUAL INCOME",
                    id: "5ce904aa-c2e4-446f-a373-0f4eee9d6f1a",
                    type: "number",
                    visible: true,
                    ui: { col_size: 5, widget: "currency" },
                    modelPath: "borrowers[0].borrowerFinanancial.income"
                  },
                  {
                    label: "MONTHLY DEBT",
                    component_id: "MONTHLY DEBT",
                    id: "3c3e375b-7ae7-44dd-8258-0e494fbdaed4",
                    type: "number",
                    visible: true,
                    ui: { col_size: 5, widget: "currency" },
                    modelPath: "borrowers[0].borrowerFinanancial.monthlyDebt"
                  }
                ]
              },
              {
                label: "Mortgage Deliquency",
                component_id: "Mortgage Deliquency",
                id: "8b8b6888-69a4-458f-887c-b009352bb131",
                type: "field_group",
                ui: {},
                visible: true,
                fields: [
                  {
                    label: "30 Day Deliqunecies in Last 12 Months",
                    component_id: "30 Day Deliqunecies in Last 12 Months",
                    id: "7775d2e3-97e7-4646-aaaa-d8fc2f09c7b1",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.30DaysMortgageLatestin12Months"
                  },
                  {
                    label: "60 Day Deliqunecies in Last 12 Months",
                    component_id: "60 Day Deliqunecies in Last 12 Months",
                    id: "13c147f3-1a47-4be8-b4e3-66f18113c785",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.60DaysMortgageLatestin12Months"
                  },
                  {
                    label: "90 Day Deliqunecies in Last 12 Months",
                    component_id: "90 Day Deliqunecies in Last 12 Months",
                    id: "d2e09d4c-1ff6-4353-8c96-f2139510cca5",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.90DaysMortgageLatestin12Months"
                  },
                  {
                    label: "30 Day Deliqunecies in Last 13-24+ Months",
                    component_id: "30 Day Deliqunecies in Last  13-24+ Months",
                    id: "04be4604-1dc7-4065-b3b8-6048de8d9eb6",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.30DaysMortgageLatestin24Months"
                  },
                  {
                    label: "60 Day Deliqunecies in Last  13-24+ Months",
                    component_id: "60 Day Deliqunecies in Last  13-24+ Months",
                    id: "2e1fa7aa-82f7-4895-bda8-e9926e9b97a9",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.60DaysMortgageLatestin24Months"
                  },
                  {
                    label: "90 Day Deliqunecies in Last  13-24+ Months",
                    component_id: "90 Day Deliqunecies in Last  13-24+ Months",
                    id: "1a48f174-b9a3-4716-9434-7e9de7f18c40",
                    type: "number",
                    visible: true,
                    ui: { col_size: 4 },
                    modelPath: "borrowerFinancialHistory.90DaysMortgageLatestin24Months"
                  }
                ]
              },
              {
                label: "Default and Bankruptcy",
                component_id: "Default and Bankruptcy",
                id: "83590b14-4b31-4fde-938a-e19d3afd4686",
                type: "field_group",
                visible: true,
                ui: {},
                fields: [
                  {
                    label: "Notice of Default (NOD)",
                    component_id: "Notice of Default (NOD)",
                    id: "dabae052-684f-4ef5-94b5-746b32f2ce84",
                    type: "enum",
                    ui: { col_size: 6, widget: "dropdown" },
                    visible: true,
                    modelPath: "borrowerFinancialHistory.noticeOfDefaultForeClosure",
                    selectMultiple: false,
                    options: [
                      {
                        id: "Never or none in the last 7 years",
                        text: "Never or none in the last 7 years",
                        value: null,
                        visible: true
                      },
                      {
                        id: "None in last 60 months",
                        text: "None in last 60 months",
                        value: "60",
                        visible: true
                      },
                      {
                        id: "None in last 48 months",
                        text: "None in last 48 months",
                        value: "48",
                        visible: true
                      },
                      {
                        id: "None in last 36 months",
                        text: "None in last 36 months",
                        value: 36,
                        visible: true
                      },
                      {
                        id: "None in last 24 months",
                        text: "None in last 24 months",
                        value: 24,
                        visible: true
                      },
                      {
                        id: "None in last 18 months",
                        text: "None in last 18 months",
                        value: 18,
                        visible: true
                      },
                      {
                        id: "None in last 12 months",
                        text: "None in last 12 months",
                        value: 12,
                        visible: true
                      },
                      {
                        id: "Currently in Default",
                        text: "Currently in Default",
                        value: 0,
                        visible: true
                      },
                      {
                        id: "None in last 6 months",
                        text: "None in last 6 months",
                        value: 6,
                        visible: true
                      },
                      {
                        id: "Within last 6 months",
                        text: "Within last 6 months",
                        value: 1,
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "Type",
                    component_id: "NOD Type",
                    id: "6e074f2c-d736-44b7-8dbe-f878769641a2",
                    type: "enum",
                    ui: { col_size: 6, widget: "dropdown" },
                    visible: true,
                    modelPath: "borrowerFinancialHistory.nodType",
                    selectMultiple: false,
                    options: [
                      {
                        value: 1,
                        text: "Foreclosure/Default",
                        id: "Foreclosure/Default",
                        visible: true
                      },
                      {
                        value: 2,
                        text: "Short Sale",
                        id: "Short Sale",
                        visible: true
                      },
                      {
                        value: 3,
                        text: "Deed in Lieu",
                        id: "Deed in Lieu",
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "Bankruptcy",
                    component_id: "Bankruptcy",
                    id: "f90b9127-984f-4979-b6a9-54850a50b1de",
                    type: "enum",
                    ui: { col_size: 6, widget: "dropdown" },
                    visible: true,
                    modelPath: "borrowerFinancialHistory.bankruptcyInMonths",
                    selectMultiple: false,
                    options: [
                      {
                        id: "Never or none in the last 7 years",
                        text: "Never or none in the last 7 years",
                        value: null,
                        visible: true
                      },
                      {
                        id: "None in last 60 months",
                        text: "None in last 60 months",
                        value: 60,
                        visible: true
                      },
                      {
                        id: "None in last 48 months",
                        text: "None in last 48 months",
                        value: 48,
                        visible: true
                      },
                      {
                        id: "None in last 36 months",
                        text: "None in last 36 months",
                        value: 36,
                        visible: true
                      },
                      {
                        id: "None in last 24 months",
                        text: "None in last 24 months",
                        value: 24,
                        visible: true
                      },
                      {
                        id: "None in last 18 months",
                        text: "None in last 18 months",
                        value: 18,
                        visible: true
                      },
                      {
                        id: "None in last 12 months",
                        text: "None in last 12 months",
                        value: 12,
                        visible: true
                      },
                      {
                        id: "Currently in Bankruptcy",
                        text: "Currently in Bankruptcy",
                        value: 0,
                        visible: true
                      },
                      {
                        id: "None in last 6 months",
                        text: "None in last 6 months",
                        value: 6,
                        visible: true
                      },
                      {
                        id: "Within last 6 months",
                        text: "Within last 6 months",
                        value: 1,
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "Type",
                    component_id: "Bankruptcy Type",
                    id: "84f18a33-4f00-4e89-86d8-f5f8073fd0f1",
                    type: "enum",
                    ui: { col_size: 6, widget: "dropdown" },
                    visible: true,
                    modelPath: "borrowerFinancialHistory.bkType",
                    selectMultiple: false,
                    options: [
                      {
                        value: 1,
                        text: "Chapter 7",
                        id: "Chapter 7",
                        visible: true
                      },
                      {
                        value: 2,
                        text: "Chapter 11",
                        id: "Chapter 11",
                        visible: true
                      },
                      {
                        value: 3,
                        text: "Chapter 13",
                        id: "Chapter 13",
                        visible: true
                      }
                    ]
                  }
                ]
              },
              {
                label: "Supplemental Funding",
                component_id: "Supplemental Funding",
                id: "754d6679-bdd8-4e76-a912-01469629bfee",
                type: "field_group",
                visible: false,
                visible_check: {
                  handler: "835dd9a9-6ed0-4614-9e3b-3fc3e967eddd"
                },
                ui: {},
                fields: [
                  {
                    label: "Gift Funds $",
                    component_id: "Gift Funds $",
                    id: "527db63c-03af-41ca-b22e-49271978324d",
                    type: "number",
                    visible: true,
                    ui: { col_size: 3, widget: "currency" },
                    modelPath: "borrowers[0].giftFundsDollar"
                  },
                  {
                    label: "Gift Funds %",
                    component_id: "Gift Funds %",
                    id: "fe086ca9-9436-446f-b14c-b674ca7add32",
                    type: "number",
                    visible: true,
                    ui: { col_size: 3, widget: "percent" },
                    modelPath: "borrowers[0].giftFundsPercent"
                  },
                  {
                    label: "Seller Concessions $",
                    component_id: "Seller Concessions $",
                    id: "9c6d72a7-eaf8-49c6-b567-5ddc642f8543",
                    type: "number",
                    visible: true,
                    ui: { col_size: 3, widget: "currency" },
                    modelPath: "borrowers[0].sellerDollar"
                  },
                  {
                    label: "Seller Concessions %",
                    component_id: "Seller Concessions %",
                    id: "860c9dac-0b0b-426f-a93d-ef26e7f7e9c8",
                    type: "number",
                    visible: true,
                    ui: { col_size: 3, widget: "percent" },
                    modelPath: "borrowers[0].sellerPercent"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: "66cb28eb-7754-499e-bc0c-c399728f9598",
        type: "screen",
        name: "Subject Property Information",
        fields: [
          {
            type: "field_group",
            name: "VISIBLE",
            fields: [
              {
                label: "Property Type",
                component_id: "Property Type",
                ui: {
                  col_size: 4,
                  widget: "dropdown"
                },
                selectMultiple: false,
                visible: true,
                required: true,
                id: "cc00d48d-34a9-4ea0-9b5b-34fabce99b8c",
                type: "enum",
                encompass_ids: [
                  {
                    id: "property.type",
                    description: "Property Type",
                    modelPath: "property.type"
                  }
                ],
                options: [
                  {
                    id: "9ff2c760-4363-4e1a-a606-5d0be49b1473",
                    text: "1-Family",
                    value: "1-Family",
                    visible: true
                  },
                  {
                    id: "cf7b0923-cc52-4cb9-93ea-c03ec74644e4",
                    text: "2-Family",
                    value: "2-Family",
                    visible: true
                  },
                  {
                    id: "1d3f29bb-dd6d-4a88-8ee4-9051dddc6f4f",
                    text: "3-Family",
                    value: "3-Family",
                    visible: true
                  },
                  {
                    id: "1dd14b51-a5ea-428b-875b-6621aedb5778",
                    text: "4-Family",
                    value: "4-Family",
                    visible: true
                  },
                  {
                    id: "d3d3571f-5bcb-4513-9da1-fa3bb5fe7327",
                    text: "Condo 1-4 Story",
                    value: "5",
                    visible: true
                  },
                  {
                    id: "4c7d0953-8436-4bba-ade3-f4784cc6e8e3",
                    text: "Condo 5-8 Story",
                    value: "6",
                    visible: true
                  },
                  {
                    id: "2220f28e-26af-46e8-9e75-ce4440a83150",
                    text: "Condo 9+ Story",
                    value: "7",
                    visible: true
                  },
                  {
                    id: "de498a43-4b61-4a50-974e-cd68e208f741",
                    text: "Condo Detached",
                    value: "12",
                    visible: true
                  },
                  {
                    id: "735b8c58-e5b1-468b-a3d9-9a0cb0f5c6aa",
                    text: "Townhouse",
                    value: "8",
                    visible: true
                  },
                  {
                    id: "a8b9d01e-83b2-4322-84f8-d447a8853835",
                    text: "Coop",
                    value: "9",
                    visible: true
                  },
                  {
                    id: "c239caf9-ce50-4fff-9ca2-84b024c95ed9",
                    text: "Double-wide Mobile",
                    value: "10",
                    visible: true
                  },
                  {
                    id: "e824fb69-2274-4271-bdc9-177a8e324ee4",
                    text: "Manufactured",
                    value: "11",
                    visible: true
                  },
                  {
                    id: "9104e226-4a1e-408f-9d69-a428d757727b",
                    text: "MH Advantage",
                    value: "14",
                    visible: true
                  },
                  {
                    id: "1a92bf3c-6a65-4fb0-861d-d2dfa4357210",
                    text: "PUD",
                    value: "13",
                    visible: true
                  },
                  {
                    id: "74ee9669-ca9b-4364-b775-bfd62fda96ed",
                    text: "PUD-Attached",
                    value: "15",
                    visible: true
                  },
                  {
                    id: "7205ed6b-a82a-4b3a-9444-4cdf9eb5e05a",
                    text: "MH CHOICEHome",
                    value: "16",
                    visible: true
                  }
                ]
              },
              {
                label: "Occupancy Type",
                component_id: "Occupancy Type",
                ui: {
                  col_size: 4,
                  widget: "dropdown"
                },
                selectMultiple: false,
                required: true,
                visible: true,
                id: "df69c396-fa9d-43b7-9a09-34ff6b54e465",
                type: "enum",
                encompass_ids: [
                  {
                    id: "property.use",
                    description: "Occupancy Type",
                    modelPath: "property.use"
                  }
                ],
                options: [
                  {
                    id: "042dfcb7-e40b-4304-ad47-5689f07ec47e",
                    text: "Pending Sale/Sold",
                    value: "4",
                    visible: true
                  },
                  {
                    id: "f7c80eb3-bee6-44d1-952f-1feb1773018d",
                    text: "Primary Residence",
                    value: "1",
                    visible: true
                  },
                  {
                    id: "14b7a9c9-9511-427b-a982-2090360479c7",
                    text: "Secondary Residence",
                    value: "2",
                    visible: true
                  },
                  {
                    id: "7b473682-61e5-4f05-8b1e-42ebf7cecf36",
                    text: "Investment/Rental",
                    value: "3",
                    visible: true
                  },
                  {
                    id: "80dbc9fc-026d-47d1-97e8-390daefc04cd",
                    text: "Other",
                    value: "5",
                    visible: true
                  }
                ]
              },
              {
                label: "Rural/Unique",
                component_id: "Rural/Unique",
                id: "152a038f-55c3-4f41-ad93-378b430c43d4",
                type: "boolean",
                ui: {
                  widget: "checkbox"
                },
                visible: true,
                encompass_ids: [
                  {
                    id: "property.rural",
                    description: "Rural/Unique",
                    modelPath: "property.rural"
                  }
                ]
              },
              {
                label: "Address",
                component_id: "Address",
                id: "bca947c4-0f5d-426d-82fc-03e3d4d58b83",
                type: "field_group",
                visible: true,
                ui: {},
                fields: [
                  {
                    label: "Street Address",
                    component_id: "Street Address",
                    id: "10d39fa8-307c-464f-96ed-b3fcad27404f",
                    type: "string",
                    visible: true,
                    ui: { col_size: 5, widget: "text" },
                    encompass_ids: [
                      {
                        id: "property.streetAddress",
                        description: "Street Address",
                        modelPath: "property.streetAddress"
                      }
                    ]
                  },
                  {
                    label: "Unit type",
                    component_id: "Unit type",
                    id: "986a708b-06a7-4a58-ac71-0a0c7fe39ac9",
                    type: "enum",
                    visible: true,
                    ui: { col_size: 5, widget: "dropdown" },
                    encompass_ids: [
                      {
                        id: "property.unitTypeId",
                        description: "Unit type",
                        modelPath: "property.unitTypeId"
                      }
                    ],
                    options: [
                      {
                        id: "Apartment",
                        text: "Apartment",
                        value: "2",
                        visible: true
                      },
                      {
                        id: "Basement",
                        text: "Basement",
                        value: "3",
                        visible: true
                      },
                      {
                        id: "Building",
                        text: "Building",
                        value: "4",
                        visible: true
                      },
                      {
                        id: "Condo",
                        text: "Condo",
                        value: "5",
                        visible: true
                      },
                      {
                        id: "Department",
                        text: "Department",
                        value: "6",
                        visible: true
                      },
                      {
                        id: "Floor",
                        text: "Floor",
                        value: "7",
                        visible: true
                      },
                      {
                        id: "Front",
                        text: "Front",
                        value: "8",
                        visible: true
                      },
                      {
                        id: "Hangar",
                        text: "Hangar",
                        value: "9",
                        visible: true
                      },
                      {
                        id: "Key",
                        text: "Key",
                        value: "10",
                        visible: true
                      },
                      {
                        id: "Lobby",
                        text: "Lobby",
                        value: "11",
                        visible: true
                      },
                      {
                        id: "Lot",
                        text: "Lot",
                        value: "12",
                        visible: true
                      },
                      {
                        id: "Lower",
                        text: "Lower",
                        value: "13",
                        visible: true
                      },
                      {
                        id: "None",
                        text: "None",
                        value: "1",
                        visible: true
                      },
                      {
                        id: "Office",
                        text: "Office",
                        value: "14",
                        visible: true
                      },
                      {
                        id: "Penthouse",
                        text: "Penthouse",
                        value: "15",
                        visible: true
                      },
                      {
                        id: "Pier",
                        text: "Pier",
                        value: "16",
                        visible: true
                      },
                      {
                        id: "Rear",
                        text: "Rear",
                        value: "17",
                        visible: true
                      },
                      {
                        id: "Room",
                        text: "Room",
                        value: "18",
                        visible: true
                      },
                      {
                        id: "Side",
                        text: "Side",
                        value: "19",
                        visible: true
                      },
                      {
                        id: "Space",
                        text: "Space",
                        value: "20",
                        visible: true
                      },
                      {
                        id: "Stop",
                        text: "Stop",
                        value: "21",
                        visible: true
                      },
                      {
                        id: "Suite",
                        text: "Suite",
                        value: "22",
                        visible: true
                      },
                      {
                        id: "Trailer",
                        text: "Trailer",
                        value: "23",
                        visible: true
                      },
                      {
                        id: "Unit",
                        text: "Unit",
                        value: "24",
                        visible: true
                      },
                      {
                        id: "Upper",
                        text: "Upper",
                        value: "25",
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "Unit Number",
                    component_id: "Unit Number",
                    id: "c8fb9f99-6737-4080-9be7-9abc146437cf",
                    type: "number",
                    visible: true,
                    ui: { col_size: 5 },
                    encompass_ids: [
                      {
                        id: "property.unitNumber",
                        description: "Unit Number",
                        modelPath: "property.unitNumber"
                      }
                    ]
                  },
                  {
                    label: "Zip Code",
                    component_id: "Zip Code",
                    id: "ea47c3a2-80ab-402a-866b-0b94d39137ec",
                    type: "string",
                    visible: true,
                    ui: { col_size: 5, widget: "text" },
                    encompass_ids: [
                      {
                        id: "property.zip",
                        description: "Zip Code",
                        modelPath: "property.zip"
                      }
                    ]
                  },
                  {
                    label: "State",
                    component_id: "State",
                    id: "2104c208-a90d-4206-8191-6fd503e750f1",
                    type: "enum",
                    ui: { col_size: 5, widget: "dropdown" },
                    required: true,
                    visible: true,
                    encompass_ids: [
                      {
                        id: "property.state",
                        description: "State",
                        modelPath: "property.state"
                      }
                    ],
                    options: Object.entries(USStateTypes || {}).map(
                      ([stateCode, stateData]) => ({
                        id: randomUUID(),
                        text: stateCode,
                        value: stateCode,
                        visible: true
                      })
                    )
                  },
                  {
                    label: "City",
                    component_id: "City",
                    id: "46791b50-5339-46e4-ac50-a1753ec6820b",
                    type: "string",
                    visible: true,
                    ui: { col_size: 5, widget: "text" },
                    encompass_ids: [
                      {
                        id: "property.city",
                        description: "City",
                        modelPath: "property.city"
                      }
                    ]
                  },
                  {
                    label: "County",
                    component_id: "County",
                    id: "f52cdcdc-9ba9-4369-84db-b3591c573cc1",
                    type: "enum",
                    visible: true,
                    ui: { col_size: 5, widget: "dropdown" },
                    encompass_ids: [
                      {
                        id: "property.county",
                        description: "County",
                        modelPath: "property.county"
                      }
                    ],
                    options: [
                      {
                        id: "d62d1ad1-6f66-4b80-94c6-d85946256dc7",
                        text: "No values yet",
                        value: "No values yet",
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: "field_group",
            name: "DETAILED",
            fields: [
              {
                label: "Annual Property Taxes",
                component_id: "Annual Property Taxes",
                id: "7e8175d9-a843-448a-9359-8e5ecb0f85ac",
                required: true,
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                encompass_ids: [
                  {
                    id: "property.tax",
                    description: "Annual Property Taxes",
                    modelPath: "property.tax"
                  }
                ]
              },
              {
                label: "Annual Homeowners Insurance",
                component_id: "Annual Homeowners Insurance",
                id: "c9c9df86-96c2-473d-a1f7-4824fa6098c8",
                required: true,
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                encompass_ids: [
                  {
                    id: "property.insuranceAmount",
                    description: "Annual Homeowners Insurance",
                    modelPath: "property.insuranceAmount"
                  }
                ]
              },
              {
                label: "Monthly Association Fees",
                component_id: "Monthly Association Fees",
                id: "c6ea1e71-e879-4be5-a12d-1b4c3f175798",
                required: true,
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                encompass_ids: [
                  {
                    id: "property.associationFee",
                    description: "Monthly Association Fees",
                    modelPath: "property.associationFee"
                  }
                ]
              },
              {
                label: "Monthly Rental Income",
                component_id: "Monthly Rental Income",
                id: "e6bc2824-3ff5-4c20-a4a4-eeb514e0e3cf",
                required: true,
                type: "number",
                ui: {
                  col_size: 3,
                  widget: "currency"
                },
                visible: true,
                encompass_ids: [
                  {
                    id: "property.rentalIncome",
                    description: "Monthly Rental Income",
                    modelPath: "property.rentalIncome"
                  }
                ]
              },
              {
                label: "Other Real Estate Owned",
                component_id: "Other Real Estate Owned",
                id: "7da8a038-9556-4ad5-ab1c-9edb79f29c8c",
                type: "field_group",
                visible: true,
                fields: [
                  {
                    label: "Occupancy Type",
                    component_id: "Occupancy Type",
                    id: "3d629597-befe-4799-a461-64ede0213038",
                    type: "enum",
                    visible: true,
                    required: true,
                    ui: {
                      col_size: 6,
                      widget: "dropdown"
                    },
                    encompass_ids: [
                      {
                        id: "otherRealEstate.use",
                        description: "Occupancy Type",
                        modelPath: "otherRealEstate[0].use"
                      }
                    ],
                    options: [
                      {
                        id: "45c784e5-b3ee-41df-a141-bb3c2878142a",
                        text: "Pending Sale/Sold",
                        value: "4",
                        visible: true
                      },
                      {
                        id: "0b449815-d318-4496-bea2-6322b049f2a1",
                        text: "Primary Residence",
                        value: "1",
                        visible: true
                      },
                      {
                        id: "549999cd-2443-4a6d-824c-c71f0654f021",
                        text: "Secondary Residence",
                        value: "2",
                        visible: true
                      },
                      {
                        id: "0e2ec437-3342-40b6-8f1d-eda0e4d84baa",
                        text: "Investment/Rental",
                        value: "3",
                        visible: true
                      },
                      {
                        id: "0d74c2f1-3be8-44bd-a41a-ca9d35c5b349",
                        text: "Other",
                        value: "5",
                        visible: true
                      }
                    ]
                  },
                  {
                    label: "Market Value",
                    component_id: "Market Value",
                    id: "b8194e93-9fda-44b1-a41e-f01561c6b5c7",
                    type: "number",
                    visible: true,
                    required: true,
                    ui: {
                      col_size: 6,
                      widget: "currency"
                    },
                    encompass_ids: [
                      {
                        id: "otherRealEstate.value",
                        description: "Market Value",
                        modelPath: "otherRealEstate[0].value"
                      }
                    ]
                  },
                  {
                    label: "Mortgage Balance",
                    component_id: "Mortgage Balance",
                    id: "ada3d700-2c07-45d1-a631-7be03778c93c",
                    type: "number",
                    visible: true,
                    required: true,
                    ui: {
                      col_size: 6,
                      widget: "currency"
                    },
                    encompass_ids: [
                      {
                        id: "otherRealEstate.mtgBalance",
                        description: "Mortgage Balance",
                        modelPath: "otherRealEstate[0].mtgBalance"
                      }
                    ]
                  },
                  {
                    label: "Mortgage Payment",
                    component_id: "Mortgage Payment",
                    id: "f4bcc965-e97f-4c0a-8a40-7cf8df299834",
                    type: "number",
                    visible: true,
                    required: true,
                    ui: {
                      col_size: 6,
                      widget: "currency"
                    },
                    encompass_ids: [
                      {
                        id: "otherRealEstate.monthlyPayment",
                        description: "Mortgage Payment",
                        modelPath: "otherRealEstate[0].monthlyPayment"
                      }
                    ]
                  },
                  {
                    label: "Rental Income",
                    component_id: "Rental Income",
                    id: "809267ef-399a-409e-8925-e1e3b6e5351c",
                    type: "number",
                    visible: true,
                    ui: {
                      col_size: 6,
                      widget: "currency"
                    },
                    encompass_ids: [
                      {
                        id: "otherRealEstate.rentalIncome",
                        description: "Rental Income",
                        modelPath: "otherRealEstate[0].rentalIncome"
                      }
                    ]
                  }
                ],
                ui: {}
              }
            ]
          }
        ]
      }
    ]
  },
  schemaRules: [
    {
      name: "isHELOC",
      id: "213beec3-7caf-45be-9cec-67359ee890b3",
      modifiedOn: "1742246221430",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "74ec6dbb-0ea7-4686-9d75-93ad36660f26",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "66"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "21"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "64"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "117"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "77"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "90"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "65"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanTerms", "67"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "canSetLoanOfficer",
      id: "62af1e06-20c3-4904-ba36-166c5ba2daf1",
      modifiedOn: "1742823745518",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "7d5c581a-9e93-4bf9-ad6b-2962f0d190ae",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["Rights.AssignLoanOfficer", "true"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["isBuyside OR isSellside OR isValidate", "true"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "isFirstMortgageHeloc",
      id: "322159db-fd06-4203-88cc-ba7dd14707bc",
      modifiedOn: "1742823745518",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "7d5c581a-9e93-4bf9-ad6b-2962f0d190ae",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "213beec3-7caf-45be-9cec-67359ee890b3"
              },
              {
                op: "eddabb96-3e46-429f-93e8-3d33e02e4639"
              }
            ]
          }
        }
      ]
    },
    {
      name: "isSecondMortgageHeloc",
      id: "f377baba-059d-423f-8702-de8281d8ec57",
      modifiedOn: "1742823857019",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "cad58c5a-22d8-4b8a-b193-0c68ac90e38a",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "213beec3-7caf-45be-9cec-67359ee890b3"
              },
              {
                op: "305c1831-0004-4a2e-8cdb-6bf85c1a0029"
              }
            ]
          }
        }
      ]
    },
    {
      name: "isCashOutRefinance",
      id: "d72d3321-a47a-4b43-be78-54a8a7e0ced2",
      modifiedOn: "1742824013136",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "3234cf2e-026f-46cb-9f22-7859e3f782a6",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "213beec3-7caf-45be-9cec-67359ee890b3"
              },
              {
                op: "isFieldNotEqualToValue",
                params: ["loanInformation.loanPurpose", "3"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "Hide Commitment And Delivery Type",
      id: "b8e31c77-8a8f-4f43-b748-ab4d3404fa3d",
      modifiedOn: "1743622153822",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "c08bc7cc-e5d9-492e-9d51-a647639b331c",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "isFieldNotEqualToValue",
                params: ["nonLoanValues.loanChannel", "4"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["nonLoanValues.viewDeliveryAndCommitment", "false"]
              },
              {
                op: "f34ac495-78b1-4884-9833-58a786f9d15f"
              }
            ]
          }
        }
      ]
    },
    {
      name: "Is Banked - Wholesale and TPO UI",
      id: "f34ac495-78b1-4884-9833-58a786f9d15f",
      modifiedOn: "1743622135426",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "be14b89e-e650-4816-9100-65301269a79c",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["nonLoanValues.isTPO", "true"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["nonLoanValues.loanChannel", "2"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "hasSupplementalFunding",
      id: "835dd9a9-6ed0-4614-9e3b-3fc3e967eddd",
      modifiedOn: "1743022983199",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "35031db6-a357-4f00-93c5-1aee90f9a27b",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldNotEqualToValue",
                params: ["loanInformation.lienPosition", "2"]
              },
              {
                op: "isFieldNotEqualToValue",
                params: ["loanInformation.loanPurpose", "2"]
              },
              {
                op: "isFieldNotEqualToValue",
                params: ["loanInformation.loanPurpose", "3"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "isGovtLoan",
      id: "dc1c8abd-0d8e-4d6f-ba01-36e484bccc05",
      modifiedOn: "1742824147814",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "f2255246-484b-40b6-8580-d04a81e8ab59",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["standardProducts", "2"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["standardProducts", "3"]
              },
              {
                op: "isFieldEqualToValue",
                params: ["standardProducts", "4"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "isTargetLabelRate",
      id: "db74b73a-2117-43db-9352-ef915287f987",
      modifiedOn: "1742840465936",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "adc02132-0d61-4510-8fb3-981733e73119",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.targetLabel", "Rate"]
              }
            ]
          }
        }
      ]
    },
    {
      name: "isSubordinateHELOC",
      id: "96052c26-2b63-4c86-a969-6f86c0166187",
      modifiedOn: "1742245724801",
      isPreConfigured: true,
      type: "rule",
      instructions: [
        {
          id: "76a06859-b79d-4465-a3bd-45488cc29c38",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.lienPosition", 1]
              },
              {
                op: "213beec3-7caf-45be-9cec-67359ee890b3"
              }
            ]
          }
        }
      ]
    },
    {
      isPreConfigured: true,
      modifiedOn: "1739919294689",
      name: "isRefi",
      type: "rule",
      id: "49cf07c3-2f06-41a1-8c5e-64580fa0730b",
      instructions: [
        {
          id: "149de332-f34f-43de-a560-49ae3bb9f3f8",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.loanPurpose", 2]
              },
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.loanPurpose", 3]
              }
            ]
          }
        }
      ]
    },
    {
      isPreConfigured: true,
      modifiedOn: "1739919294689",
      name: "hasSecondMortgageValue",
      type: "rule",
      id: "efe3a42a-b0a2-4330-801c-6a637537a19b",
      instructions: [
        {
          id: "952ced8a-513e-491d-af21-75c2a86339e1",
          type: "instruction",
          condition: {
            op: "or",
            params: [
              {
                op: "isNotEmptyField",
                params: ["loanInformation.secondMortgageAmount"]
              },
              {
                op: "isNotEmptyField",
                params: ["loanInformation.otherPayment"]
              }
            ]
          }
        }
      ]
    },
    {
      isPreConfigured: true,
      modifiedOn: "1739919294689",
      name: "isLienPositionSecondMortgage",
      type: "rule",
      id: "305c1831-0004-4a2e-8cdb-6bf85c1a0029",
      instructions: [
        {
          id: "68a11ae4-7fa2-45db-8ed9-b2fddae4d44f",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.lienPosition", 2]
              }
            ]
          }
        }
      ]
    },
    {
      isPreConfigured: true,
      modifiedOn: "1739919294689",
      name: "isLienPositionFirstMortgage",
      type: "rule",
      id: "eddabb96-3e46-429f-93e8-3d33e02e4639",
      instructions: [
        {
          id: "23a11ae4-7fa2-45db-8ed9-b2fddae4d44f",
          type: "instruction",
          condition: {
            op: "and",
            params: [
              {
                op: "isFieldEqualToValue",
                params: ["loanInformation.lienPosition", 1]
              }
            ]
          }
        }
      ]
    }
  ]
};
const SamplePricingSearchConfigurationStore = [
  {
    channel: "Brokered",
    draftSchema: NSSampleSchema,
    modifiedOn: (/* @__PURE__ */ new Date()).getTime().toString(),
    publishedSchema: null,
    userLastEdited: "Dave"
  }
];
export {
  EmptySchema,
  NSSampleSchema,
  SamplePricingSearchConfigurationStore
};
