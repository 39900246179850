import { jsx } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { useState } from "react";
import { useFormState } from "react-hook-form";
import { useLoanOfficerFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import {
  useLoanDataFieldSelectorHook,
  useUserData,
  useLookupOptions,
  generateOfficeUsersComboboxOptionsWithItemLayout
} from "./utils/utils";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Loanofficer = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const loanOfficerFieldUpdateLogic = useLoanOfficerFieldUpdateLogic();
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const { errors } = useFormState({ control: schemaForm.control });
  const { firstName = "", lastName = "", officeName = "" } = useUserData();
  const loanOfficerId = useLoanDataFieldSelectorHook(
    "loanInformation.loanOfficerId"
  );
  const defaultOfficer = [
    {
      firstName: `${firstName}`,
      lastName: `${lastName}`,
      office: `${officeName}`,
      userId: Number(`${loanOfficerId.value}`)
    }
  ];
  const eppsUserName = useLoanDataFieldSelectorHook("eppsUserName");
  let officersOptions = useLookupOptions("transformedOfficeUsers") || [];
  const defaultOfficersOption = generateOfficeUsersComboboxOptionsWithItemLayout(
    "officeUsers",
    defaultOfficer
  );
  if (!officersOptions?.length) {
    officersOptions = defaultOfficersOption;
  }
  const loanofficerInitialValue = loanOfficerId ? officersOptions.find((option) => option.value === loanOfficerId) : null;
  const selectedOfficerValue = eppsUserName ? officersOptions.find((option) => option.value === eppsUserName) : null;
  const [loanOfficer, setLoanOfficer] = useState(
    selectedOfficerValue ?? loanofficerInitialValue
  );
  if (!loanOfficer) {
    setLoanOfficer(defaultOfficersOption[1]);
  }
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      inputID: name,
      required,
      feedbackMessage,
      hasError: !!errors[id],
      validationMessage: errors[id]?.message?.toString(),
      children: /* @__PURE__ */ jsx(
        DSSingleComboBox,
        {
          ...renderedField,
          allOptions: officersOptions,
          onChange: loanOfficerFieldUpdateLogic(
            renderedField,
            schemaForm,
            id,
            setLoanOfficer
          ),
          selectedValue: loanOfficer,
          id: pathValue,
          disabled: isDisabled
        }
      )
    }
  ) });
};
export {
  Loanofficer
};
